import React from 'react'
import HotAuction from '../../assets/images/hot-auction.jpeg'


const MainImage = () => {
    return (
        <>
            <img src={HotAuction} alt="" className="img-responsive" />
        </>
    )
}

export default MainImage
